.productsContainer{
    margin: 0px 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}


.productsHolder{
    width: 100%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.productImgHolder{
    background-color: #ebebeb;
    border-radius: 10px;
    min-height: 25.6655vw;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.productImgHolder img{
    width: 70%;
    transition: 0.5s;
    z-index: 1000;
}

.productImgHolder img:hover{
    width: 80%;
}

.productCard-productsPage{
    width: 30%;
    margin: 40px 0;
    display: flex;
    min-height: 50vh;
    flex-direction: column;
    align-items: center;
}

.button-ProductsPage{
    border: 2px solid black;
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    transition: 0.7s;
    background-color: black;
    color: white;
}

.button-ProductsPage:hover{
    border: none;
    color: white;
    background-color: #AE796A;
}
.button-ProductsPage-clicked{
    border: 2px solid black;
    margin-top: 20px;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 20px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    transition: 0.7s;
    border: none;
    color: white;
    background-color: #AE796A;
    cursor: not-allowed;
}
.productCard-productsPage p{
    text-align: center;
    width: 80%;
    height: 30px;
}
.productPrice{
    font-weight: bold;
    font-size: 18px;
}
.priceAndSaleContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
}
.salePercentage{
    font-family: 'poppins';
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    display: flex;
    font-size: 19px;
    color: red;
    font-weight: 700;
    justify-content: end;
    align-items: flex-end;
    height: 24.6655vw !important;
    width: 92% !important;
}
.swiper-slide-2{
    width: 30% !important;
}
.line {
    position: absolute;
    width: 42%;
    height: 3px;
    background-color: black;
    opacity: 0.5;
    top: 52%;
    left: 4%;
    transform: translateY(-45%) rotate(0deg);
}
.custom-underline-productHolder {
    text-decoration: none; /* Remove the default underline */
    position: relative; /* Set the position to relative */
  }
  
 .custom-underline-productHolder::after {
    content: ""; /* Add an empty content element */
    position: absolute; /* Set the position to absolute */
    bottom: 55%; /* Position it at the bottom of the parent element */
    left: 20%; /* Position it at the left edge of the parent element */
    width: 60%; /* Make it span the full width of the parent element */
    /* Add the custom underline with blue color and 2px height */
    border-bottom: 2px solid black;
    margin-bottom: 0px;
    margin-left: 0px;
  }
@media screen and (max-width: 1080px){

    .button-ProductsPage {
    font-size: 16px;
    width: 90%;
    margin-top: 0;
    }
    .custom-underline-productHolder::after {
        bottom: 50%; /* Position it at the bottom of the parent element */
        left: 20%; /* Position it at the left edge of the parent element */
        margin-bottom: 0px;
        margin-left: 0px;
      }
    .productsContainer{
        margin: 0 10px;
    }
    .productsHolder{
        justify-content: center;
    }
    .productCard-productsPage{
        width: 40%;
        gap: 5px;
        min-height: 43vh;
        margin: 5px 0;
    }
    
    .productCard-productsPage p{
        width: 93%;
        height: 70px;
        font-size: 13px;
        margin: 5px 0;
    }

    .productImgHolder{
        min-height: 31.6655vw;
        pointer-events: none;
    }

    .productImgHolder img {
        width: 60%;
        transition: 0.5s;
    }
    .productPrice{
        height: 20px !important;
        font-size: 14px;
    }
    .priceAndSaleContainer{
        width: 100%;
    }
    .salePercentage{
        /* top: 79%;
        left: 49%; */

        font-size: 14px;
        height: 28.6655vw;
        width: 90%;

    }
    .productName{
        font-size: 15px;
    }
    .salePercentage{
        height: 29.6655vw !important;
    }
}