.myContactsContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.myContactsContainer h1{
    font-family: poppins-bold;
}
.myContactsHolder{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    overflow-y: auto;
    height: 75vh;
}
.myContactsCardContainer{
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 14px;
    gap: 5px;
    min-width: 290px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
}
.contactCardpart1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactCardpart1>img{
    width: 40%;
}

.contactCardpart1 p{
    font-family: poppins-bold;
}

.contactCardpart2{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 90%;
}

.phone-email{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.phone-email>img{
    width: 15%;
}


.myContactsCardContainer button{
    width: 80%;
    padding: 10px 20px;
    border-radius: 8px;
    background: #AE796A;
    border: none;
    color: white;
    font-family: poppins;
    cursor: pointer;
    margin: 20px 0;
}

.deleteContact{
    position: relative;
    left: 45%;
    cursor: pointer;
}

@media screen and (max-width: 1080px){
    .deleteContact {
        left: 45%;
        }
    .myContactsContainer h1{
        font-size: 17px;
    }
    .myContactsHolder{
        overflow: unset;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    .myContactsContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}