.aboutContainer{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 7%;
}
.sectionTitle h1{
    /* font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    background: linear-gradient(91deg, #AE796A 39%, #00223E 81.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    margin-bottom: 0;
    text-align: center;
    font-family: 'poppins-bold';
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.5px;
    background: linear-gradient(86deg, #AE796A 40%, #00223E 71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.footer-link{
    text-decoration: underline;
    text-underline-position: under;
    color: dodgerblue;
}
.sectionDescription p{
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 130% */
    padding: 0 8%;
}
.questionsList{
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
    padding: 0 15%;
    margin-bottom: 8%;
    
}
.answer{
    padding:0 5px;
}
/* .faq:not([open])
{
    max-height: 4vw;
    transition: max-height 1s ease; 
} */

/* input{
    display: none;
} */
.faq
{
    max-height: 5vw;
    position: relative;
    margin: 10px;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
}
.faq[open]
{
    max-height: 45vw;
}
.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    /* background-color: #ccc;
    border: 1px solid #999; */
    border-radius: 4px;
    user-select: none;
}

  
.faq[open] .open-answer {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out; /* Add animation for open */
}
.faq:not([open]) .open-answer{
    transform: rotate(0);
    transition: transform 0.3s ease-in-out;
}
.open-answer svg {
    opacity: 0.5;
}

.open-answer{
    display: flex;
    align-items: center;
    pointer-events: none;
    top: 0.75em;
    svg {
        display: block;
    }
}
summary {
    list-style: none;

    &:focus {
        outline: none;
    }

    &:hover .open-answer svg {
        opacity: 1;
    }
}
.answer p {
    font-size: 20px;
    text-align: left;
    padding: 1% 2%;
    opacity: 0.7;
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2)0px 2px 8px 0px;
}
@media screen and (max-width: 1000px){
    .aboutContainer{
        margin: 0%;
    }
.sectionTitle h1{
    font-size: 200%;
}
.sectionDescription p {
    font-size: 13px;
    line-height: 200%;
}
.questionsList {
    font-size: initial;
    line-height: 150%;
    list-style-type: none;
}
.faq {
    margin: 2%;
}
.faq:not([open]){
    height: auto;
    max-height: 80px;
}
.faq[open]{
    height: auto;
    max-height: 500px;
}
.answer p {
    line-height: 200%;
    font-size: 100%;
    padding: 3% 6%
}
.questionsList{
    padding: 0 4% 0 12%;
}
.open-answer{
    align-self: flex-start;
}

}