.profilesContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin: 0;
    transition:all 1s;
}


.profileSettingsHolder{
    width: 25%;
}

.profileSettingsHolder>.profileContainer{
    width: 100%;
    height: 450px;
    overflow: hidden;
}

.pHolder{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
    transition: 0.5s;
    border-radius: 36px;
    display: flex;
    height: 74vh;
}

.pHolder:hover{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.60);
}

.settingsButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.settingsButtons button:nth-child(1){
    outline: none;
    border: none;
    background-color:#D9D9D9;
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    width: 49%;
    font-family: jakarta;
}

.settingsButtons button:nth-child(2){
    outline: none;
    border: none;
    background-color:#D9D9D9;
    padding: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    width: 49%;
    font-family: jakarta;
}

.addProfileButton{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background-color: white;
    height: 300px;
    width: 200px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
    transition: 0.5s;
}

.addProfileButton:hover{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.60);
}

@media screen and (max-width: 1080px){
    .pHolder{
        height: auto;
    }
    .profilesContainer{
        flex-direction: column;
        width: 90%;
    }
    .profileSettingsHolder{
        width: 90%;
    }
    .addProfileButton{
        margin-bottom: 10px;
    }
}