.thankYouContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 30%;
    padding-top: 20%;
}

.Title {
    font-family: poppins-bold;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 81.5px;
    background: linear-gradient(90deg, #AE796A 1.37%, #00223E 96.9%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
}

.thankYouContainer .checkMail {
    color: #000;
    font-family: Poppins;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 81.5px;
    /* 254.688% */
    margin-top: 0;
}


.thank-you-container {
    text-align: center;
    padding: 20px;
}

.order-details {
    display: flex;
    flex-direction: column;
    font-family: poppins;
    gap: 18px;
    justify-content: space-between;
    margin: 20px;
}

.order-info,
.order-description {
    border-radius: 15px;
    border: 5px solid black;
    /* border-radius: 5px; */
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 16px;
    padding: 20px 20px 40px 20px;
    align-items: center;
}
 
.checkOutHeader {
    color: #333;
}

.order-info p {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.detailsBoxCheckout{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}


@media screen and (max-width: 1080px){
    .detailsBoxCheckout{
        flex-direction: column;
    }
}