.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    font-family: poppins;   
    /* background: linear-gradient(180deg, #F0EFEF 0%, #FEFEFE 25.83%); */
    background: url("../images/CardTap-Hand.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 42vw;
    border-radius: 97px;
}

.section1 p {
    margin-top: -7%;
    font-size: 22px;
}


.home-but {
    background: linear-gradient(90deg, #AE796A 0%, #00223E 100%);
    color: white;
    padding: 15px 60px;
    font-size: 24px;
    transition: 1s;
    border-radius: 20px;
}

.home-but:hover {
    color: black;
    border: 0.1px solid black;
    background: white;
}


.section1 >*{
    /* margin: 0 30px; */
    margin: 30px 60px;
}

.section1-left {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 40px;
}

.section1-right {
    width: 55%;
}

.section1-right img {
    width: 100%;
}

/* .section1 h1 {
    color: black;
    font-size: 80px;
    font-weight: bolder;
    margin-bottom: 0;
}

.section1 span {
    position: absolute;
    top: 370px;
    left: 360px;
    height: 120px;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    font-size: 80px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;

}

.section1 span::before {
    content: "faster";
    animation: animate infinite linear 3s;
    padding-left: 10px;

}

@keyframes animate {
    0% {
        content: "faster";
    }


    25% {
        content: "easily";
    }

    50% {
        content: "with just";
    }

    100% {
        content: "one tap";
    }
} */

.section1-left p {
    font-size: 1.45vw;
}

.section1-left a {
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    color: white;
    font-size: 1.45vw;
    margin-top: 13%;
    padding: 0.7vw 2.1vw;
    transition: 0.5s;
}

.section1-left a:hover {
    border: 1px solid linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    background-image: linear-gradient(45deg, #f3ec78, #af4261);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}


.section2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5vw;
    font-family: poppins;
}

.cardsHolder-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 2.2vw;
    flex-wrap: wrap;
}

.card {
    text-align: center;
    width: 20%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card h3 {
    font-size: 1.85vw;
}

.card p {
    margin: 0 auto;
    width: 80%;
    font-size: 1.7vw;
}


.lower {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.section5 h1 {
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    font-size: 4.4vw;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;
}


.productImgHolder-home {
    background-color: #ebebeb;
    border-radius: 10px;
    min-height: 25.6655vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.productImgHolder-home img {
    width: 80%;
    transition: 0.8s;
}

.productImgHolder-home:hover img {
    width: 100%;
}


.tag-img {
    background-color: #ebebeb;
    border-radius: 10px;
    min-height: 25.6655vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tag-img img {
    transition: 1s;
    width: 50%;
}

.tag-img img:hover {
    width: 60%;
}


.section6-button {
    border: 2px solid black;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.466vw;
    font-weight: 600;
    display: block;
    text-align: center;
    transition: 0.7s;
    background-color: black;
    color: white;
}

.button-HomePage-clicked {
    border: 2px solid black;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1.466vw;
    font-weight: 600;
    display: block;
    text-align: center;
    transition: 0.7s;
    border: none;
    color: white;
    background-color: #AE796A;
    cursor: not-allowed;
}

.section6-button:hover {
    border: none;
    color: white;
    background-color: #AE796A;
}

.wrapper-home {
    display: flex;
    margin-bottom: -80px;
}

.wordsContainer-home {
    font-family: poppins-bold;
}

.wrapper-home .wordsContainer-home {
    margin: 0;
    padding: 0;
    font-size: 3vw;
}

.words-home {
    overflow: hidden;
    height: 8vw;
    position: relative;
    left: 76%;
    bottom: 30%;
}

.seeProductsContainer {
    text-align: center;
    opacity: 0.8;
    /* margin-bottom: 140px; */
    margin-bottom: 65px;
    margin-top: -40px;
}

.seeProducts {
    text-decoration: underline;
    cursor: pointer;
    font-family: 'poppins';
    font-size: 20px;
}

.words-home span {
    font-size: 3.5vw;
    display: block;
    height: 9vw;
    color: #0e6ffc;
    animation: spin_words 6s infinite;
    background: linear-gradient(270deg, #00223E 50%, #AE796A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes spin_words{
    10%{
        transform: translateY(-112%);
    }

    25% {
        transform: translateY(-100%);
    }

    35% {
        transform: translateY(-212%);
    }

    50% {
        transform: translateY(-200%);
    }

    60% {
        transform: translateY(-312%);
    }

    75% {
        transform: translateY(-300%);
    }
}

.mobile-view {
    display: none;
}

.outer {
    position: relative;
}

.outer h1 {
    font-size: 65px;
    margin: 50px 90px;
}

.inner {
    position: absolute;
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    font-size: 65px;
    margin-left: 9px;
    top: 40px;
    overflow: hidden;
    display: inline-block;
}

.inner span {
    animation: animate 10s ease infinite;
    position: relative;
    background: linear-gradient(270deg, #00223E 1%, #AE796A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes animate {

    0%,
    100% {
        top: 0;
    }

    20% {
        top: 0px;
    }

    25% {
        top: -50px;
    }

    35% {
        top: -50px;
    }

    40% {
        top: -100px;
    }

    55% {
        top: -100px;
    }

    65% {
        top: -150px;
    }

    75% {
        top: -150px;
    }

    85% {
        top: -200px;
    }

    90% {
        top: -200px;
    }

    95% {
        top: -250px;
    }
}

.section4 {
    display: none;
}

.section3 {
    display: none;
}

.brief-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.section3-desktop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 22px;
    font-family: poppins;
    background: url("../images/mob.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 120%;
    background-color: white;
    position: sticky;
    z-index: 9;
    border-radius: 90px;
}

.section3-desktop h1 {
    font-size: 40px;
    font-family: poppins-bold;
    background: linear-gradient(83deg, #AE796A 3.84%, #00223E 53.04%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: white;
    margin-top: 50px;
}

.section3-desktop>p {
    font-size: 25px;
}

.section3-desktop>* {
    margin: 20px 0 20px 70px;
}

.brief-lower {
    width: 30%;
    margin-bottom: 40px;
}

.section4-desktop {
    background: url("../images/section4-img.png");
    background-color: black;
    min-height: 600px;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: auto 50%;
    margin: -100px auto;
    width: 90%;
    z-index: 1;
}

.section5 {
    display: none;
}

.section5-desktop {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 70px;
    z-index: 9;
    background-color: white;
}

.section5-desktop>* {
    margin: 50px;
}

.sec5-left {
    width: 45%;
}

.sec5-right {
    margin: 0;
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
    width: 50%;
    background: url("https://res.cloudinary.com/cardtap/image/upload/v1697503890/ezgif.com-optimize_h9vhy8.gif"), lightgray 50%;
    min-height: 700px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sec5-brief {
    width: 100%;
    margin-bottom: 40px;
}

.sec5-left h1 {
    background: linear-gradient(83deg, #AE796A 3.84%, #00223E 53.04%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-color: white;
    font-size: 40px;
}

.sec5-left>p {
    margin-bottom: 50px;
    width: 60%;
    font-family: poppins;
}

.section6 {
    display: none;
}

.section6-desktop {
    margin: 40px auto;
    width: 90%;
}

.productCard {
    text-align: center;
}

.productCard p {
    margin: 8px auto;
    height: 40px;
}

.section1-mobile {
    display: none;
}

.logos-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    width: 90%;
    /* margin: 200px auto; */
    margin: 60px auto;
}

.logos-holder img {
    margin: 0 50px;
    width: 150px;
    height: auto;
}

.logosContainer {
    width: 100%;
}

.section7 h1 {
    text-align: center;
    /* font-size: 80px; */
    font-size: 65px;
    background: linear-gradient(86deg, #AE796A 21.49%, #00223E 77.65%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-but-mobile {
    display: none;
}

@media screen and (max-width: 1080px) {
    .seeProducts {
        font-size: 14px;
    }

    .section6-desktop {
        display: none;
    }

    .section5-desktop {
        display: none;
    }

    .section4-desktop {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .homePageContainer {
        width: 90%;
        margin: 0 auto;
        gap: 50px;
    }

    .section1 {
        display: flex;
        margin: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        width: 90%;
    }

    .section1-left {
        width: 100%;
    }

    .wrapper-home .wordsContainer-home {
        font-size: 22px;
        padding: 18px;
    }

    .words-home span {
        height: 31vw;
        font-size: 22px;
    }

    .section1-left p {
        padding: 20px;
        font-size: 15px;
        margin: 0;
    }

    .section1-lowerpart-holder {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }

    .section1-left a {
        font-size: 12px;
        padding: 18px 27px;
        position: relative;
        left: -70px;
        bottom: 56px;
    }

    .section3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FBFBFB;
        border-radius: 80px;
        font-family: poppins;
        width: 90%;
        border-radius: 20px;
        z-index: 9;
        margin-bottom: -30px;
    }

    .lower {
        flex-direction: column;
        width: 100%;
    }

    .brief-lower {
        display: none;
    }

    .section3-left {
        width: 90%;
    }

    .section3-left h1 {
        font-family: poppins-bold;
        font-size: 29px;
        text-align: center;
    }

    .section3-left p {
        text-align: center;
    }

    .section3-left h3 {
        text-align: center;
    }

    .section3-left h2 {
        text-align: center;
        font-size: 15px;
        margin-bottom: 11%;
    }

    .section3-right {
        width: 100%;
    }

    .section3-left {
        width: 75%;
        margin: 4.398vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .section3-left h1 {
        background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
        font-size: 4.4vw;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bolder;
    }

    .section3-right {
        width: 60%;
    }

    .section3-right img {
        width: 100%;
    }

    .section4 {
        margin: -10.7vw 5.131vw -5.131vw 5.131vw;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 36.665vw;
        width: 90%;
        z-index: 1;
    }

    .cardsHolder-home {
        margin-top: 100px;
        width: 80%;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .card {
        background: white;
        border-radius: 16px;
        padding: 0px 15px;
        min-height: 35vh;
        width: 80%;
        margin: 0 auto;
        padding: 20px;
    }

    .card h3 {
        font-size: 20px;
        font-family: poppins-bold;
    }

    .card p {
        font-size: 15px;
        font-family: poppins-light;
    }

    .section4 img {
        margin: 80px auto;
        width: 100%;
    }

    .section5 {
        width: 90%;
        border-radius: 20px;
        background-color: #F2F2F2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: -40px;
        z-index: 1;
        min-height: 51.331vw;
        position: sticky;
    }

    .section5 img {
        width: 100%;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        margin-top: -20px;
    }

    .section5 h1 {
        font-size: 25px;
        font-family: poppins-bold;
    }

    .cards-holder-sec5 {
        width: 100%;
        margin-top: 20px;
    }

    .sec5-p {
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20.5px;
        width: 70%;
    }

    .section6 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 5.13vw;
        gap: 0.733vw;
        flex-wrap: wrap;
        width: 90%;
    }

    .productCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        width: 100%;
        text-align: center;
    }

    .productImgHolder-home {
        min-height: 58.6655vw;
    }

    .productImgHolder-home img {
        width: 70%;
    }

    .productImgHolder-home:hover img {
        width: 70%;
    }

    .section6-button {
        width: 99%;
        text-align: center;
        padding: 0.733vw 0vw;
        font-size: 3.466vw;
    }
    .button-HomePage-clicked{
        font-size: 3.466vw;
        padding: 1.5vw 0;
        text-align: center;
        width: 99%;
    }
    .section7 {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section7 h1 {
        width: 90%;
        text-align: center;
        font-size: 25px;
        background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    .container2 {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .pro {
        position: absolute;
        top: 50%;
        left: -100%;
        animation: slideInFromLeft 5s infinite;
    }

    @keyframes slideInFromLeft {
        0% {
            left: -100%;
        }

        50% {
            left: 100%;
        }

        100% {
            left: -100%;
        }
    }

    .section3 h1 {
        width: 80%;
        background: linear-gradient(83deg, #AE796A 3.84%, #00223E 53.04%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        font-size: 22px;
    }

    .section3-desktop {
        display: none;
    }

    .section1 {
        display: none;
    }

    .section1-mobile {
        background-color: white;
        border-radius: 22px;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .wrapper-home {
        width: 100%;
    }

    .seeProductsContainer {
        margin-bottom: 20px;
    }

    .section1-mobile p {
        margin: 50px 20px 20px 20px;
        font-family: poppins;
        font-size: 13px;
        width: 80%;
    }

    .logos-holder img {
        width: 100px;
        margin: 0 25px;
    }

    .words-home {
        height: 10vw;
        left: 77%;
        bottom: 31%;
    }

    .section1-mobile-img {
        width: 100%;
        background: url("../images/CardTap-Hand-Mobile.png"), lightgray 50% / cover no-repeat;
        background-position: center;
        border-radius: 32px;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 380px;
    }

    .home-but-mobile {
        font-size: 15px;
        margin: -20px 0;
        display: block;
    }

    .tag-img {
        min-height: 58.6655vw;
    }

    .tag-img img {
        width: 50%;
    }

    .tag-img img:hover {
        width: 60%;
    }

    .logos-holder {
        margin: 30px auto;
    }
}