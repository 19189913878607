.corporate-analytics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px 30px;
    padding: 10px;
}

.corporate-upperPart {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
}

.chart-container {
    width: 45%;
}

.corporate-stats-desktop {
    width: 54%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}



.stat-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 24.721px;
    padding: 15px 25px;
    width: 35%;
    background: linear-gradient(119deg, #AE796A -13.54%, #292F3C 118.09%);
    box-shadow: 9.71163px 11.65395px 77.69302px 0px rgba(84, 135, 183, 0.08);
}

.stat-button {
    display: inline-flex;
    padding: 12.36px;
    align-items: flex-start;
    gap: 12.36px;
    border-radius: 19.776px;
    background: #F2F2F2;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
    cursor: pointer;
}

.stat-box-left,
.stat-box-right {
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    gap: 15px;
    width: 40%;
}

.stat-box-right-upper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}
.stat-box-right-upper {
    margin-right: 10px;
}

.stat-title {
    display: flex;
    font-family: poppins-light;
    font-size: 12px;
    text-align: center;
}

.stat-tap {
    font-family: poppins;
    font-size: 20px;
}

.stat-month {
    opacity: 0.75;
    font-size: 13px;
    display: flex;
    font-family: 'poppins';
    text-align: center;
}

.stat-totaltaps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    background: linear-gradient(119deg, #AE796A -13.54%, #292F3C 118.09%);
    border-radius: 50px;
    padding: 7px 30px;
    margin: 10px 0;
    font-size: 10px;
}
.ring-stat{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.ChartsContainer{
    width: 40%;
    display: flex;
}
.PieChart{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.corporate-lowerPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
    font-family: poppins-light;
    padding: 5px;
    border-radius: 15px;
}


.ring-stat-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.ring-stat-list li {
    display: flex;
    width: 60%;
    text-align: left;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.ring-stat-list li>span {
    width: 80%;
}

.ring-stat-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.corporate-upperPart-holder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.corporate-stats-mobile {
    display: none;
}
.stat-box{
    height: 150px;
}

.anal-splitter{
    height: 200px;
    border: 1px solid black;
}

@media screen and (max-width: 1080px) {
    .stat-month{
        font-size: 10px;
    }
    .ChartsContainer{
        width: 100%;
        display: flex;
    }
    .anal-splitter{
        height: 0px;
        width: 90%;
    }
    .corporate-analytics-container {
        width: 100%;
        margin: 0;
        gap: 30px;
    }

    .corporate-upperPart {
        width: 90%;
        margin: 0 auto;
        justify-content: center;
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .corporate-stats-desktop {
        display: none;
    }

    .corporate-stats-mobile {
        display: block;
        margin: 0 auto;
    }

    .upperPart-headline {
        text-align: center;
    }

    .corporate-upperPart-holder {
        flex-direction: column;
        width: 100%;

    }

    .stat-totaltaps {
        padding: 0px 35px;
        font-size: 8px;
    }

    .chart-container {
        width: 100%;
    }

    .stat-box {
        width: 75%;
        height: 130px;
    }

    .stat-title {
        font-size: 10px;
    }

    .corporate-stats {
        width: auto;
        margin-top: 2vh;
        gap: 15px;
        justify-content: center;
    }

    .corporate-lowerPart {
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        width: 90%;
        margin-bottom: 40px;
    }

    .ring-stat-holder {
        flex-direction: column;
    }

    .ring-stat-list li>span {
        width: 65%;
    }

    .stat-box-left,
    .stat-box-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

    }
}