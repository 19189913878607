html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);

}

@font-face {
  font-family: jakarta;
  src: url('../fonts/Jakarta/PlusJakartaSans-Medium.ttf');
}

@font-face {
  font-family: poppins;
  src: url('../fonts/Poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: poppins-light;
  src: url('../fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: poppins-bold;
  src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-links-holder-mobile{
  display: none;
}

.background {
  width: 100%;
}

.container {
  width: 95%;
  margin: 20px auto;
  background: #F2F2F2;
  border-radius: 40px;
}

.shop-company {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: -110px;
  }

.footer-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 95%;
  background: #F2F2F2;
  border-radius: 40px;
  padding-bottom: 50px;

}

.homePageContainer {
  padding: 30px 0;
}

.footer-text {
  width: 250px;
  height: 54px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  display: flex;
  margin: 30px;
  align-items: center;
  text-align: center;
  color: #898989;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-logo {
  width:60%;
  flex: none;
  margin: 35px 0 15px 0;
  order: 0;
  flex-grow: 0;
}

.footer-links-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex: none;
  flex-grow: 0;
}

.footer-list {
  list-style: none;
  padding-inline-start: 0;
}

.shop-list,
.company-list,
.legal-list,
.newsletter {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: flex-start;
  padding: 0px;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
  font-size: 125%;
}

.shop-list {
  width: 20%;
}

.company-list {
  width: 25%;
}

.legal-list {

  width: 20%;

}

.newsletter {
  width: 35%;
}

.footer-links-holder p {
  font-family: 'Poppins', sans-serif;
}

.footer-holder a,
.footer-holder span {
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 13px;
  margin: 10px 0;
  line-height: 24px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
}

.footer-newsletter {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.newsletter-button {
  cursor: pointer;
  width: 55px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 30px;
  background: #AE796A;
  border-radius: 7px;
  border: 0;
}
.contactUsLink{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 30px;
  justify-content: space-between;
}

.contactUsLink span{
  font-family: Poppins;
  font-size: 17px;
}

.email-input {
  width: 100%;
  height: 38px;
  left: 0px;
  top: 0px;
  font-size: 17px;
  border: 0;
  margin-left: 10px;
  background: #F1F1F1;
  border-radius: 4px;
  outline: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: black;
}

.socialmedia-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.socialmedia-list a {
  font-size: 20px;
}

.footer-socialmedia p {
  width: 223px;
  height: 20px;

  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.cartContainer {
  background-color: white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.20);
  overflow-y: scroll;
  height: 100%;
  width: 33%;
  position: absolute;
  right: 5px;
  background: #F2F2F2;
}

.footer-socialmedia {
  display: block;
  margin-bottom: -20px;

}

.sale-bar{
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: white;
  margin: 0;
  padding: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
  font-size: 20px;
  font-family: poppins;
}

@media screen and (max-width: 1080px) {
  .footer-logo {
    width: 45%;
  }
  
  .homePageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-links-holder-mobile{
    display:block;
  }
  .footer-links-holder{
    display: none;
  }
  .cartContainer {
    width: 75%;
  }

  .footer-links-holder {
    flex-direction: column;
    align-items: center;
  }

  .newsletter {
    width: 100%;
    align-items: center;
    padding-top: 80px;
  }

  .shop-company {
    gap: 120px;
    justify-content: center;
    padding-left: 0px;
  }

  .footer-holder {
    padding-bottom: 50px;
  }

  .footer-socialmedia {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }
  .contactUsLink{
    font-size: 25px;
    width: 100%;
  }
  .contactUsLink span{
    font-size: 15px;
  }
}