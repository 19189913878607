.businessContainer {
    margin: 70px 70px 70px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.bus-call-but-mobile{
    display: none;
}

.headerButton {
    position: relative;
    background-color: #3498db;
    color: #fff;
    font-size: 18px;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .headerButton::before {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    border: 2px solid #F2F2F2;
    background: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    animation: pulse 2s linear infinite;
  }
  
  .headerButton:hover {
    background-color: #2980b9;
  }
  
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }

.headerContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
}

.dashboardImgContainer video{
    justify-content: center;
}

.headerContainer h1 {
    text-align: center;
    font-family: poppins-bold;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    background: linear-gradient(180deg, #AE796A 0%, #00223E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 59%;
    margin-bottom: 0px;
}


.headerButton{
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    background: linear-gradient(90deg, #AE796A 0%, #00223E 100%);
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    text-transform: capitalize;
}
.dashboardHeader {
    font-size: 60px;
    font-weight: bolder;
    color: white;
    background-color: black;
    width: 100%;
    text-align: center;
    padding: 100px 0; 
    border-radius: 97px;
    z-index: 1;
}

.dashboardImgContainer {
    margin-top: 1%;
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.dashboardTitle{
    color: #000;
    text-align: center;
    font-family: poppins-bold;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    margin: 30px 0;
}

.dashboardImgContainer img {
    width: 110%;
    margin: 0 0;
    margin-bottom: 300px;
}

.getInTouchContainer {
    width: 99%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    margin-bottom: 60px;
    border-radius: 54px;
    margin-top: 10%;
    min-height: 800px;
    background: url("../images/alaa6.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 9;
    margin-bottom: 10%;
}

.getInTouchLeft {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 30px;
    min-height: 650px;
    width: 50%;
}
.mobile-gif{
    display: none;

}
.getInTouchLeft h3 {
    font-size: 70px;
    margin: 0px 30px 20px 30px;
}


.getInTouchLeft p {
    font-size: 30px;
    margin: 0 30px;

}

.getInTouchRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 600px;
    margin: 30px;
    width: 50%;
}

.getInTouchForm {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 61px;
    margin: 20px 30px;
    background: #F2F2F2;
    /* width: 100%; */
    box-sizing: border-box;
    padding: 30px;
    gap: 10px;
    
}


.getInTouchFormInputHolder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 0 8%;
    width: -webkit-fill-available;
}

.getInTouchFormInputHolder input {
    border-radius: 20px;
    background: #D9D9D9;
    line-height: 30px;
    width: 100%;
    border: none;
    font-size: 17px;
    outline: none;
    box-sizing: border-box;
    padding: 10px 20px;

}

.bus-call-but{
    margin: 20px;
}

.getInTouchForm button {
    cursor: pointer;
    padding: 10px 45px;
    margin: 40px auto;
    width: 350px;
    /* border-radius: 20px; */
    /* background: #00223E; */
    border-radius: 14px;
    border: 1px solid #000;
    /* font-size: 30px; */
    color: white;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;    
}

.upper-img-holder{
    width: 99%;
    margin: 0 auto;
    /* background-color: black; */
    color: white;
    font-size: 64px;
    border-radius: 77px;
    z-index: 9;
}

.upper-img-holder img{
    width: 100%;
    border-radius: 50px;   
}

.getInTouchContainer-mobile{
    display: none;
}

@media screen and (max-width: 1080px){
    .bus-call-but{
        display: none;
    }
    .bus-call-but-mobile{
        display: block;
    }
    .bus-call-but-mobile a{
        font-size: 20px;
        border: none;
        border-radius: 10px;
        font-size: 15px;
        padding: 10px 10px;
    }
    .getInTouchFormInputHolder input{
        border-radius: 15px;
        padding: 1px 10px;
    }
    .getInTouchFormInputHolder{
        padding-left: 30px;
        padding: 0;

    }
    .headerContainer{
        justify-content: center;
        min-height: 100px;
    }
    .headerContainer h1{
        font-size: 22px;
    }
    .businessContainer{
        margin: 25px;
    }
    .upper-img-holder{
        border-radius: 21px;
        font-size: 15px;
    }
    .dashboardImgContainer{
        min-height: unset;
    }
    .getInTouchContainer{
        display: none;
    }
    .getInTouchContainer-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    .getInTouchContainer-mobile-img{
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 0%, #000 100%) , url("../images/alaa6.png");
        color: white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 400px;
        width: 99%;
        border-radius: 21px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        z-index: 9;
        margin-top: 20%;
    }
    .getInTouchContainer-mobile-img h1{
        margin: 2px 0;
    }
    .getInTouchContainer-mobile-img p{
        width: 50%;
        text-align: center;
    }
    .desktop-video{
        display: none;
    }
    .mobile-gif{
        width: 100%;
        height: 52vw;
        display: block;
        background:  url("../images/gif2.gif");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .dashboardTitle{
        font-size: 23px;
        margin-top: 0;
    }
    .headerContainer h1{
        width: 100%;
    }
    .headerContainer h1{
        width: 100%;
    }
    .headerContainer h1{
        width: 100%;
    }
    .headerContainer h1{
        width: 100%;
    }
    
    .getInTouchForm{
        background-color: white;
        margin-top: -135px;
        z-index: 2;
        width: 100%;
        align-items: center;
    }
    .getInTouchFormInputHolder:nth-child(1){
        margin-top: 120px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .getInTouchForm button{
        padding: 4px 0px;
        margin: 20px 0px;
        width: 80%;
        font-size: 15px;
        color: BLACK;
        background: white;
    }
}