.dashboardContainer{
    position: relative;
    display: flex;
    flex-direction: row;
}

.dashboardNavbar{
    display: flex;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    position: relative;
    gap: 80px;
    background: black;
    border-radius: 40px;
    width: 100%;
}

.dashboardNavbar-img {
    width: 90%;
    text-align: center;
    margin-top: 70px;
}

.dashboardNavbar-img img{
    width: 80%;
    margin: 0 auto;
    object-fit: cover;
}

.dashboardNavbar-list{
    align-items: center;
    list-style: none;
    list-style-type: none;
    padding: 0;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
}

.dashboardNavbar-list li{
    width: 91%;
    padding: 0 10px;
    color:white;
}
.link-dashboardNavbar{
    color:white;
}

.icons-dashboardNavbar{
    width: 30%;
    display: inline-block;
    text-align: center;
}

.text-dashboardNavbar{
    width: 70%;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
}

.dashboardNavbar-list .active{
    width: 100%;
    display: inline-block;
    padding: 10px 0px;
    border-radius: 20px;
    color:black;
    background-color: white;
}

.dashboardContent{
    width: 80%;
}

.dashboardContent > *{
    margin: 30px;
}

.dashboardNavbarHolder{
    width: 20%;
}

.navBurger{
    display: none;
}

.navBurgerHolder{
    display: none;
}

.mobileDashboardNavbar{
    display: none;
}

.desktopDashboardNavbar{
    width: 20%;
}

.logout-btn{
    margin: 20px 10px;
    outline: none;
    width: 80%;
    border: none;
    padding: 10px 5px;
    background: white;
    color: black;
    border-radius: 15px;
    font-size: 17px;
    font-family: jakarta;
    cursor: pointer;
    transition: 0.5s;
}

.tutorial-button{
    position: absolute;
    top: 21vh;
    left: 9.5vw;
    background: linear-gradient(90deg, #AE796A 0%, #00223E 100%);
    transition: background-color 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: 999999;
    color: white;
    padding: 10px;
    width: 15%;
    font-size: 17px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}

.tutorial-button:hover{
    background: white;
    color: black;
}

.tutorial-button::before {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    border: 2px solid #F2F2F2;
    background: transparent;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
    animation: pulse 2s linear infinite;
  }

@media screen and (max-width: 1080px){

    .tutorial-button{
        left: 30vw;
        width: 80%;
    }
    .desktopDashboardNavbar{
        display: none;
    }
    .dashboardContainer{
        height: auto;
        min-height: 96vh;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
    }
    .dashboardContent > * {
        margin: 0px;
    }
    
    .dashboardContent{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .navBurger{
        display: block;
        text-align: right;
    }
    .dashboardImgHolder{
        display: flex;
        width: 50%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .dashboardImgHolder img{
        width: 100%;
    }
    .navBurgerHolder{
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .dashboardNavbar{
        position: absolute;
        left: 9px;
        top: 10px;
        width: 60%;
    }
    /* .mobileDashboardNavbar{
        display: block;
        position: absolute;
        left: -100%;
        top: 0;
        transition: 850ms;
        height: 100vh;
        z-index: 10;
    }
    .mobileDashboardNavbar.navActive{
        left: 0;
        transition: 450ms;
    } */
}