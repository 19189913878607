.contactInput{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #d9d9d9;
    border-radius: 40px;
    font-family: poppins;
    font-size: 12px;
}
.upperInputs{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.mobile-contactInfoHeader{
    display: none;
}
.inputHolderUpper{
    margin: 0px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
}
.inputHolderUpper label{
    width: 100%;
}
.inputHolderUpper input{
    width: 80%;
    margin-top: 12px;
    outline: none;
    border: none;
    padding: 5px;
    border-radius:10px ;
}
.lowerInputs{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}
.inputHolderLower{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80%;
}
.inputHolderLower label{
    width: 100%;
}
.inputHolderLower input{
    width: 91%;
    margin-top: 12px;
    outline: none;
    border: none;
    padding: 6px;
    border-radius:10px ;
}

.saveButtonContactInfo{
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.buttonsHolder button{
    border-radius:10px ;
}

@media screen and (max-width: 1080px){
    .contactInput{
        width: 100%;
    }
    .socialMediaHolder{
        margin: 15px 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        height: auto;
    }
    .mobile-contactInfoHeader{
        display: block;
    }
    .upperInputs{
        flex-direction: column;
        width: 100%;
    }
    .inputHolderUpper {
        width: 80%;
        justify-content: center;
        align-items: flex-start;
    }
    .inputHolderUpper input {
        width: 96%
    }
    .rightPart-contacts{
        gap: 30px;
        width: 80%;
    }
    .inputHolderLower input{
        width: 96%
    }
}