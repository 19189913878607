.socialMediaHolder{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.linksUpper{
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    border-radius: 16px;
}
.sectionHolder-sm{
    margin:10px 20px;
}
.sectionHolder-sm p{
    font-family: poppins;
    font-size: 17px;
    border-bottom: 1px solid black ;
    width: max-content;
    padding-bottom: 3px;
}
.appImgHolder img{
    transition: 0.5s;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.appImgHolder img:hover{
    /* background-color:white ;
    border-radius: 10px; */
    width: 40px;
    height: 40px;
}
.buttonsHolder-sm{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 100%;
    margin-top: 10px;
}

.buttonsHolder-sm *{
    padding: 20px 50px;
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
    border-radius: 16px;
    
}

/* .buttonsHolder-sm button:nth-child(1){
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
} */

/* .buttonsHolder-sm button:nth-child(1){
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
} */

.appImgHolder{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.smInputContainer{
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #D9D9D9;
    padding: 10px 0;
    gap: 10px;
}

.smInputHolder{
    width: 70%;
    display: flex;
    flex-direction: row;
    background: white;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding: 5px 10px ;
}

.smInputHolder img{
    width: 25px;
    height: 25px;
}

.smInputHolder input{
    width: 100%;
    border: none;
    outline: none;
}
.vertical-space{
    background: black;
    height: 23px;
    width: 1px;
}

.buttonSM{
    width: 20%;
    padding: 8px 0;
    border-radius: 10px;
    border: 1px solid #000;
    background: #FFF;
    cursor: pointer;
    transition: 0.5s;
}
.smInputContainer button:hover{
    background-color: black;
    color: white;
}
.smInputContainer button:disabled{
    cursor: not-allowed;
}
@media screen and (max-width: 1080px){
    .buttonsHolder-sm{
        width: auto;
    }
    .appImgHolder {      
        flex-wrap: wrap;
    }
    .linksUpper {
        width: 85%;
    }
    .smInputContainer{
        width: 85%;
        flex-direction: column;
    }
    .smInputContainer form{
        width: 85%;
    }
    .buttonSM{
        width: 90%;
    }
    .sectionHolder-sm p{
        font-size: 13px;
    }
}