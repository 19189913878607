


.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 35px 50px;
    flex-wrap: nowrap;
}

.list{
    list-style: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-right: 50px;
}

.imgHolder{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imgHolder img{
    height: -webkit-fill-available;
}

.list li{
    margin: 0 20px;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 540;
}

a{
    text-decoration: none;
    color: black;
    font-family: jakarta    ;
}

.loginButton{
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    transition: 0.3s;
    font-size: 15px;
}

.loginButton:hover{
    background-color: #AE796A;
    color: white;
}

.hover{
    padding: 10px !important;
    border-radius: 10px !important;
    transition: 0.3s;
}

.hover:hover{
    position: relative;
    background-color: #AE796A;
    color: white;
}

.mobile-navbar-list .active{
    background-color: #AE796A;
    color: white;
}

.cart{
    font-size: 35px;
    color: black;
}

/* .cart:hover{
    font-size: 25px;
} */

.cartItemsHolder{
    margin: 6%;
    margin-right:2%
}

.itemHolder{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* justify-content: center; */
    margin: 10px 0;
    /* gap: 20px; */
}

.itemImg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
}

.itemImg img{
    width: 100%;
}

.itemInfo{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.itemInfo span{
    font-size: 17px;
}

.actionHolder{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;
    gap: 20px;
}

.itemAction{
    margin: 0 10px 0 0;
    background-color: white;
    padding: 2px 20px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 15px;
}

.mobile-navbar{
    display: none;
}

.cart{
    padding-right: 0px;
    justify-content: flex-end;
    padding-right: 0;
}

.cart-badge{
    display: flex;
    align-self: flex-end;
    background: linear-gradient(130deg, #AE796A 44%, #00223E 90%);
    color: white;
    border-radius: 50%;
    padding: 4px 8px;
    margin-top: 3px;
    font-size: 14px;
    margin-left: 2px;
    /* border: 2px solid white; */
}

@media screen and (max-width: 1080px){
    .navbar{
        display: none;
    }
    .mobile-navbar{
        display: flex;
    }
    .mobile-navbar-list{
        list-style: none;
        background-color: white;
        position: absolute;
        left: 0;
        margin: 0;
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        gap: 25px;
        padding-top: 25px;
    }
    .taskOpenBtn{
        font-size: 1.1rem;
    }
    .mobile-navbar-list li{
        display: flex;
        justify-content: center;
        height: 50px;
        align-items: center;
    }
    .mobile-navbar-buttons{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 10px;
    }

    .mobile-navbar-buttons img{
        width: 35%;
    }
    .cart-mobile-container{
        display: flex;
        flex-direction: row;
    }
    .cart-badge{
        margin-left: -23%;
        padding: 0px 6px;
    }
}

