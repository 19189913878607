.cardsHolder{
    overflow: hidden;
    border-radius: 16px;

}

.cardsHolder-2{
    height: 58vh;
    overflow-y: auto;
    gap: 2.2vw;
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.cardContainer{
    width: 25%;
    background-color: white;
    padding: 15px;
    border-radius: 21px;
}

.dots{
    min-width: auto !important;
}

.card-upper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.card-name{
    font-family: jakarta;
    font-size: 15px;   
}

.card-middle{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
}

.card-second{
    font-family: jakarta;
    font-size: 10px;
    opacity: 0.7;   
}

.corp-img{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: black;
    border-radius: 16px;
    margin: 20px 0;
    height: 110px;
}

.corp-img img{
    width: auto;
    height: 80%;
}

.card-lower{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.taps{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-self: center;
    gap: 2px;
}

.tap{
    color: red;
}

.con{
    color:#4339F2;
}

.searchBar{
    display: flex;
    flex-direction: revert;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

.searchBar input{
    width: 80%;
    outline: none;
    border: none;
    background: #D9D9D9;
    padding: 10px 30px;
    font-size: 15px;
    font-family: poppins;
    border-radius: 22px;
}

@media screen and (max-width: 1080px){
    .searchBar input{
        padding: 10px 20px;
        font-size: 12px;
    }
    .mainPageContainer{
        margin: 0 auto;
        width: 90%;
    }
    .cardsHolder{
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .cardsHolder-2{
        justify-content: space-between;
        gap: 4.2vw;
        height: auto;
        width: 84%;
        flex-direction: column;
        overflow-y: hidden;
    }
    .cardContainer{
        padding: 35px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 80%;
        text-align: center;
        height: 20vh;
    }
    .taps{
        align-items: flex-start;
        width: 40%;
    }
    .card-name{
        font-size: 18px;
    }
    .corp-img img{
        width: 50%;
    }
    .card-second{
        font-size: 13px;
    }
    .card-lower{
        width: 100%;
    }
    .corp-img{
        height: min-content;
        width: 100%;    
    }
    .card-uppper img{
        width: 100%;
    }
}