.analyticsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}
.analytics-section1{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}
.analytics-userImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.analytics-userImg img{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.analytics-section2{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 65px;
    justify-content:center;
    align-items: center;
}
.analytics-box{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
    height: 190px;
    width: 20%;
    background-color: #D9D9D9;
    border-radius: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.analytics-section3{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.analytics-section3-part1{
    width: 40%;
}
.analytics-section3-part2{
    width: 55%;
    height: 250px;
}

@media screen and (max-width: 1080px){
    .analyticsContainer{
        width: 90%;
    }
    .analytics-section2{
        flex-direction: column;
    }
    .analytics-box{
        width: 100%;
    }
    .analytics-section3{
        flex-direction: column;
        gap: 30px;
    }
}