.business-section1{
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #D9d9d9;
    margin-top: 15px;
    padding: 15px;
    border-radius: 16px;
}
.profile-name ,.profile-job{
    border: none;
    outline: none;
    line-height: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    width: 50%;
    font-size: 15px;
    font-family: poppins;
}

.bio-business{
    width: 100%;
    margin: 15px 0;
    padding: 10px;
    border-radius: 18px;
    gap: 10px;
}

.bio-business .textarea{
    font-family: poppins;
    font-size: 12px;
    width: 100%;
    padding: 20px 5px 5px 5px;
    min-height: 100px;
    margin-top: -20px;
    border: 1px solid #00000047;
}

.bioTitle-business{
    display: flex;
    height: 20px;
    width: 20px;
    padding: 20px;
    font-size: 20px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.business-section3{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #D9d9d9;
    gap: 40px;
    border-radius: 16px;
    padding: 20px;
}

.InputContainer-business{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.businessInfo-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 90%;
}

.smInputHolder-business{
    width: 100%;
}

.buttonSM-BI{
    width: 70%;
}

.businessInfo-img{
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.buttonsHolder-business{
    width: auto;
    margin: 0;
}
.fileInput::after {
    content: " (PDFs only allowed)"; /* Change the button text here */
  }
.phoneInput{
    width: 103.5%;
}


.bTitles{
    width: 100%;
    font-family: 'poppins-bold';
    border-bottom: 1px solid black;
    font-size: larger;
}

.bt2{
    width: 100%;
    font-family: 'poppins-bold';
    border-bottom: 1px solid black;
    font-size: larger;
}

.react-international-phone-input{
    width: 100%;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.react-international-phone-country-selector-button{
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.fileInput {
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding: 7px 10px;
}
.nameJobHolder{
    width: 100%;
}
@media screen and (max-width: 1080px){
    .bio-business {
        width: 80%;
    }
    .InputContainer-business{
        width: 80%;
        padding: 10px;
    }
    .business-section3{
        gap: 20px;
        background-color: transparent;
    }
    .business-section1{
        width: 100%;
        padding: 15px 10px;
        flex-direction: column;
    }
    .business-section1 input{
        width: 80%;
    }
    .bTitles{
        width: 80%;
    }
    .nameJobHolder{
        width: 80%;
    }
}