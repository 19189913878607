.profileViewContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 85vh;
}

.profileView-leftPart{
    border-radius: 38px;
    overflow: hidden;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.platform{
    text-decoration: none;
    padding: 1px;
    border-radius: 12px;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.platformsHolder{
    margin: 15px 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 35px;
}

.profileView-rightPart{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
}

.avatars{
    background-color: #D9D9D9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 10px;
    border-radius: 16px;
    width: 80%;
}

.avatars img{
    width: 14%;
}

.cover{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    background-color: #D9D9D9;
    width: 80%;
    gap: 40px;
    padding: 10px;
    border-radius: 16px;
}

.cover img{
    width: 26%;
    margin: 10px 0;
}

.userImage{
    width: 80%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: #D9D9D9;
    border-radius: 16px;

}

.userImage *{
    margin: 0 40px;
}


.userImage img{ 
    width: 15%;
}

.profileButtons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 83.5%;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    gap: 30px;
}


.buttonsHolder{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.buttonsHolder *{
    padding: 20px 50px;
    background-color: #D9D9D9;
    border: none;
    cursor: pointer;
}

.buttonsHolder button:nth-child(1){
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.buttonsHolder button:nth-child(2){
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
}

.profileTextInput{
    width: 100%;
    padding: 20px 20px;
    background-color: #D9D9D9;
    border-radius: 16px;
    border: none;
    cursor: pointer;
    font-size: 15px;
    outline: none;
}

.file2 > label {
    
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-style: solid;
    border: none;
    background-color: #959595;
    color: black;
    font-family: poppins;
    font-weight: 900;
    width: 165px;
    height: 85px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .file2 > input[type='file'] {
    display: none;
} */

.file2 > input[type=file] {
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border:none;
    margin: 1px;
    width: 80%;
  }

.file2 > input[type=file]::file-selector-button{
    outline: none;
    border: none;
    background:#444;
    color: white;
    border-radius: 16px;
    padding: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background 0.5s ease-in-out;
}


.file2 > input[type=file]::file-selector-button:hover {
    background: #D9D9D9;
    color: black;
}



.file3{
    text-align: center;
    width: 40%;
}

.file3 > label {
    cursor: pointer;
    outline: 0;
    user-select: none;
    border-style: solid;
    border: none;
    color: black;
    font-family: poppins;
    font-weight: 900;
    border-radius: 16px;
    margin: 0;
}

/* .file3 > input[type='file'] {
    display: none;
} */

.file3 > input[type=file] {
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border:none;
    margin: 1px;
    width: 100%;
  }

.file3 > input[type=file]::file-selector-button{
    outline: none;
    border: none;
    background:#444;
    color: white;
    border-radius: 16px;
    padding: 5px;
    cursor: pointer;
    margin: 0 5px;
    transition: background 0.5s ease-in-out;
}


.file3 > input[type=file]::file-selector-button:hover {
    background: #D9D9D9;
    color: black;
}


.file3 img{
    width: 20%;
}

@media screen and (max-width: 1080px){
    .profileViewContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        width: 100%;
        height: auto;
    }
    .profileView-leftPart{
        width: 80%;
    }
    .profileView-rightPart{
        width: 100%;
        margin: 0;
        }
    .avatars img {
        width: 25%;
    }
    .cover{
        flex-direction: column;
    }
    .cover img{
        width: 50%;
    }
    .userImage{
        flex-direction: column;
        gap: 20px;
    }
    .profileButtons{
        flex-direction: column;
    }
    .profileTextInput{
        width: 90%;
    }
    .file3 img{
        width: 40%;
    }
}