.profileView-rightPart-corporate-edit {
    width: 65%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.edit-employees-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.corporate-edit {
    width: 35%;
}

.employee-edit-container {
    gap: 20px;
    height: 75vh;
    flex-direction: row;
}

.employee-edit-card-holder {
    height: 75vh;
    justify-content: flex-start;
    align-items: flex-start;
}

.employee-edit-card-container {
    width: 45%;
}

.corporate-edit-card-holder-search {
    width: 100%;
}

.employee-edit-card-container>div {
    width: 85%;
    transition: 0.3s;
    cursor: pointer;
}

.employee-edit-card-container>div:hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
}

.logout-corp {
    margin-top: 70px;
}

@media screen and (max-width: 1080px) {
    .corporate-edit {
        width: 80%;
    }

    .profileView-rightPart-corporate-edit {
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }

    .employee-edit-card-holder {
        height: auto;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .employee-edit-card-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .employee-edit-card-container>div {
        width: 75%;
        height: auto;
    }

    .cardContainer {
        justify-content: center;
        align-items: flex-start;
    }

    .card-upper {
        width: 100%;

    }

    .corporate-edit-card-holder-search {
        padding: 0;
    }

    .employee-edit-container {
        height: auto;
        flex-direction: column;
    }
}