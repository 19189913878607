.loginHolder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.loginContainer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);
    border-radius: 24px;
}

::-ms-reveal {
    display: none;
}

.loginForm {
    background-color: white;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}


.login-img {
    width: 70%;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    height: 100%;
}

.login-img img {
    width: 30%;
}

.login-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 7px;
    width: 100%;
    position: relative;
}

.login-input input {
    outline: none;
    border: none;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    border-radius: 7px;
    height: 14px;
    background: #D9D9D9;
    font-size: 11px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.password-input {
    display: flex;
    position: relative;
    width: 100%;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


.login-input label {
    opacity: 0.6;
    font-size: 13px;
}

.login-part1 {
    margin: 40px 0;
}

.login-part2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}

.loginForm button {
    display: flex;
    padding: 10px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: #A77569;
    color: white;
    border: none;
    cursor: pointer;
}

.pass-link {
    text-decoration: none;
    width: 95%;
    display: block;
    text-align: right;
    color: #007AFF;
    font-size: 12px;
    font-family: poppins-light;
}

@media screen and (max-width: 1080px) {
    .loginContainer {
        min-height: 65vh;
        height: auto;
    }

    .login-img {
        display: none;
    }

    .loginForm {
        width: 100%;
        border-radius: 24px;
        height: unset;
    }

    .login-part2 {
        width: 95%;
        padding-left: 5%;
    }

    .login-input {
        width: 100%;
    }

    .password-input {
        width: 95%;
    }

    .loginForm button {
        width: 95%;
        margin-bottom: 20px;
    }
}