.productDescriptionContainer{
    margin: 110px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-desc-section1{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.product-desc-section1-left{
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.prod-desc-items-desktop{
    width: 100%;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    flex-wrap: nowrap;
}

.other-prod-title{
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; 
    margin: 150px 0 50px 0;
    text-align: left;
    width: 100%;
}

.productImgHolder-desc{
    background-color: #ebebeb;
    border-radius: 10px;
    min-height: 25.6655vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productImgHolder-desc img{
    width: 100%;
    transition: 0.5s;
}

.productImgHolder-desc img:hover{
    width: 110%;
}

.prod-img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.prod-img img{
    width: 50%;
}

.product-desc-section1-right{
    width: 50%;
}


.product-desc-section1-right h3{
    font-size: 30px;
    margin: 20px 0;
}

.product-desc-section1-right span{
    font-size: 15px;
}

.actionButtons{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 7px;
}

.actionButtonsHolder{
    margin: 0 10px 0 0;
    background-color: white;
    padding: 2px 20px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 10px;
}

.actionButtonsHolder * {
    margin: 0 3px;
}

.prod-addToCart{
    width: 100%;
    cursor: pointer;
    padding: 3px;
    text-align: center;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    border: 1px solid #00223E;
    border-radius: 5px;
    font-weight: bolder;
    transition: 1s;
    background: black;
}
.button-ProductDescription-clicked{
    width: 100%;
    cursor: pointer;
    padding: 3px;
    text-align: center;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid #00223E;
    border-radius: 5px;
    font-weight: bolder;
    transition: 1s;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    color: white;
    border: none;
    background-clip: unset;
    -webkit-text-fill-color:unset;
    cursor: not-allowed;
    
}
.prod-addToCart:hover{
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    color: white;
    border: none;
    background-clip: unset;
    -webkit-text-fill-color:unset;
}

.prod-buy{
    display: block;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    color: white;
    text-align: center;
}

.productDescriptionHeader{
    border-bottom: 1px solid black;
    font-size: 25px;
    padding: 10px 2px;
    text-align: center;
}

.productDescriptionSec2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.productDescriptionHolder{
    width: 100%;
    font-size: 15px;
}

.productDescriptionHolder *{
    margin: 35px 0;
    opacity: 0.7;
}

.product-desc-section3{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin: 70px 0;
}


.product-desc-section3 .upperPart{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 25px;
}

.product-desc-section3 .lowerPart{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 25px;
}

.product-desc-section3 .left{
    width: 50%;
}

.product-desc-section3 .left h1 {
    color: black;
    font-size: 70px;
    margin: 30px;
    font-weight: bolder;
    margin-bottom: 0;
}


/* .product-desc-section3 .left p {
    text-align: center;
    width: 80%;
    font-weight: bold;
} */

.product-desc-section3 .right{
    width: 50%;
}

.product-desc-section3 .right img{
    width: 100%;
    border-radius: 20px;
}



/* .product-desc-section3 .left span {
    position: absolute;
    top: 1643px;
    left: 350px;
    height: 120px;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    font-size: 70px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;

}

.product-desc-section3 .left span::before {
    content: "faster";
    animation: animate infinite linear 3s;
    padding-left: 10px;

}


.product-desc-section3 .lowerPart .left span {
    position: absolute;
    top: 2445px;
    left: 985px;
    height: 120px;
    background: linear-gradient(270deg, #00223E 0%, #AE796A 100%);
    font-size: 70px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bolder;

}



@keyframes animate {
    0% {
        content: "faster";
    }


    25% {
        content: "easily";
    }

    50% {
        content: "with just";
    }

    100% {
        content: "one tap";
    }
} */


.wrapper{
    display: flex;
}
.wrapper .wordsContainer{
    margin: 0;
    padding: 0;
    font-size: 5.2vw;
}
.words{
    overflow: hidden;
    height: 8vw;
    font-size: 5.2vw;
}
.words span{
    font-size: 5.2vw;
    display: block;
    height: 8vw;
    color: #0e6ffc;
    animation: spin_words 6s infinite;
    background: linear-gradient(270deg, #00223E 50%, #AE796A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.line-Description{
    position: absolute;
    width: 8%;
    height: 3px;
    background-color: black;
    opacity: 0.5;
    top: 54.2%;
    left: 50.5%;
    transform: translateY(-45%) rotate(0deg);
}
.priceAndSaleContainer-Description{
    gap: 15px;
    display: flex;
    font-family: 'poppins';
}
.custom-underline {
  text-decoration: none; /* Remove the default underline */
  position: relative; /* Set the position to relative */
}

.custom-underline::after {
  content: ""; /* Add an empty content element */
  position: absolute; /* Set the position to absolute */
  bottom: 0; /* Position it at the bottom of the parent element */
  left: 0; /* Position it at the left edge of the parent element */
  width: 100%; /* Make it span the full width of the parent element */
  /* Add the custom underline with blue color and 2px height */
  border-bottom: 2px solid black;
  margin-bottom: 14px;
}

.contactUsPopup{
    padding: 40px !important;
    border-radius: 40px !important;
}
.popupAvatar{
    font-size: 1.75rem !important;
    background-color: transparent !important;
}
.popupText{
    font-size: 17px !important;
    font-family: 'poppins' !important;
}
@keyframes spin_words{
    10%{
        transform: translateY(-112%);
    }
     25%{
        transform: translateY(-100%);
    }
    
    35%{
        transform: translateY(-212%);
    }
    50%{
        transform: translateY(-200%);
    }
    60%{
        transform: translateY(-312%);
    }
    75%{
        transform: translateY(-300%);
    }
}

.prod-other-images{
    margin-top: 20px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.prod-other-images *{
    width: 150px;
    height: 150px;
    margin-right: 5px;
}

.actionButtonsHolder{
    cursor: pointer;
}

.mobile{
    display: none;
}
.descContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
    opacity: 0.7;
}
.descContainer p {
    margin: 0;
}

.left h2{
    font-size: 60px;

}
.left span{
    background: linear-gradient(91deg, #AE796A 58.55%, #00223E 81.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: poppins;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
}
.left p{
    font-family: poppins-bold;
    font-size: 30px;
}
.productCard-productsPage-desc{
    width: 30%;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.prod-desc-items-mobile{
    display: none;
}

.offer-container{
    overflow: hidden;
    display: flex;
    border-radius: 18px;
    width: 80%;
    position: absolute;
    top: 10%;
    left: 10%;
    margin: 0 auto;
}

.offer-holder{
    border-radius: 18px;
    box-sizing: border-box;
    padding: 15px;
    background: white;
    overflow-y: auto;
    height: 600px;
}

.cards-offer, .tags-offer {
    box-sizing: border-box;
    padding: 15px;
    border-radius: 15px;
    background-color: #ebebeb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cards-offer img{
    width: 70%;
}

.tags-offer img{
    width: 50%;
}

.offer-addToCart{
    display: block;
    width: 50%;
    padding: 15px;
    font-size: 25px;
    margin: 40px auto;
    border-radius: 18px;
}

@media screen and (max-width: 1080px){
    .offer-addToCart{
        margin: 20px auto;
        width: 80%;
    }
    .cards-offer, .tags-offer{
        flex-direction: column;
        gap: 40px;
    }
    .swiper-desc{
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .productCard-productsPage-desc{
        width: 100%;
    }
    .productCard-productsPage {
        width: 100%;
    }
    .button-ProductsPage-desc{
        border: 2px solid black;
        margin-top: 20px;
        padding: 10px 0;
        border-radius: 10px;
        font-size: 20px;
        width: 98%;
        text-align: center;
        font-weight: 600;
        transition: 0.7s;
    }
    .other-prod-title{
        width: unset;
        margin-top: 50px;
        font-size: 18px;
    }
    .prod-desc-items-mobile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .prod-desc-items-desktop{
        display: none;
    }
    .productDescriptionContainer{
        margin: 10px 10px;
        flex-direction: column;
    }
    .product-desc-section1{
        margin-top: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .prod-img{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 250px;
        overflow: hidden;
    }
    .prod-img img{
        width: 80%;
    }
    .product-desc-section1-left, .product-desc-section1-right{
        width: 90%;
    }
    .product-desc-section1-right h3{
        font-size: 18px;
        text-align: center;
    }
    .descContainer{
        text-align: justify;
    }
    .prod-other-images{
        justify-content: center;
    }
    .prod-other-images *{
        height: 90px;
        width: 90px;
    }
    .productDescriptionHolder{
        width: 90%;
    }
    .product-desc-section3{
        width: 90%;
        margin: 0px 0px 10px 0px;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
        text-align: center;
    }
    .product-desc-section3 .upperPart{
        flex-direction: column;
        gap: 0px;
    }
    .product-desc-section3 .left {
        width: 80%;
    }
    .left h2{
        font-size: 23px;
    }
    .left span{
        font-size: 23px;
    }
    .product-desc-section3 .right {
        width: 100%;
    }
    .product-desc-section3 .lowerPart{
        flex-direction: column;
    }
    .left p{
        font-size: 18px;
    }
  }