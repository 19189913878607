/* .cartPageContainer{
    padding:30px 0;
    margin: 0 3%;
    width: 40%;
    margin-left: 30%;
}
.back{
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
} */
/* .cartHeading{
    margin-top: 4%;
}
.cartHeading{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
}
.cartCount{
    font-family: Nunito;
    font-size: 14px;
    font-weight: 500;
    line-height: 5px;
    letter-spacing: 0em;
    text-align: left;
} */
/* .cart{
     align-items: center; 
     display: flex;
     flex-direction: column;
    } */
hr{
    border: 1.5px solid #D0CFCF;
    margin-top: 5%;
}
.itemsCount{
    color: #1E1E1E;
    font-family: 'poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1%;
}
.cartItems{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin: 2% 0;
    width:100%;
}
.itemImage{
    width: 8vw;
    padding: 4% 3%;
}
.itemDetails{
    width:25%;
    display: flex;
    flex-direction: column;
}
.itemTitle{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    word-break: break-word;
}
/* .itemCardType{
    font-family: Nunito;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0.7em;
} */
.itemQuantity{
    border: 1px solid #EEEEE0;
    margin-left: 2%;
}
.quantityCounter{
    background-color: white;
    padding: 7px 10px;
    gap: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    font-size: 11px;
}
.itemPrice{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: right;
    margin-left: 2.5%;
}
.itemDeleteButton{
    margin-left: 4%;
}
.checkoutButtonContainer{
    display: flex;
    flex-direction: column;
}
.checkoutButton{
    border-radius: 8px;
    background: #AE796A;
    color: white;
    padding: 2% 6%;
    align-self: center;
    font-family: poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

.cart-func-holder{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    align-items: flex-end;
    margin-bottom: 20px;
}
.itemDetails{
    width: 50%; 
}

@media screen and (max-width: 1080px){
    .cartItems{
        box-sizing: border-box;
        padding: 10px;        
    }
    .itemImage{
        width: 20vw;
    }
    .itemDetails{
        width: 60%;
    }
    .cart-func-holder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        align-items: flex-end;
    }
}