.checkoutContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 90%;

}
.checkoutHeader{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 30px;
    justify-content: center;
    align-items: flex-start;
}

.checkoutHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 100px;
    background-color: white;
    border-radius: 47px;
    padding: 30px 0;
    font-size: large;
}

.checkout-leftPart{
    width: 40%; 
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 60px;
}

.checkout-rightPart{
    width: 40%; 
    background: #D9d9d9;
}

.address-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 3px solid #AE796A;
    border-radius: 8px;
    padding: 20px;
    gap: 10px;
}

.address-form input{
    outline: none;
    border: none;
    border: 1px solid #C8C8C8;
    padding: 12px;
    border-radius: 8px;
    width: 90%;
}

.form-div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 10px 20px;
    font-size: 16px;
    font-weight: 100;
    font-family: poppins-light; 
}


.form-part1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    width: 50%;
}

.form-part3{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap:5px;
    width: 100%;
}

.form-part3 input{
    width: 95%;
}

/* Hide the up and down arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkoutCancel{
    border: 0.5px solid black;
    outline: none;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    width: 30%;
    font-size: 17px;
    font-family: poppins;
}

.checkoutConfirm{
    border: 0.5px solid black;
    outline: none;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    width: 70%;
    background-color: #AE796A;
    color: white;
    font-family: poppins;
    font-size: 17px;
}

.checkout-rightPart{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 30px;
}

.itemContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: jakarta;
    font-size: 17px;
}

.itemImgCheckout{
    display: flex;
    width: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.itemImgCheckout img{
    width: 60%;
}

.product-policy{
    font-family: poppins-light;
    opacity: 0.5;
    
}

.horizontal-line{
    border: 0.5px solid #B0B0B0;
    width: 100%;
    margin: 20px 0;
}

.orderSummary{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: poppins-light;
    margin: 20px 0;
}

.orderSummary h3{
    width: 100%;
    font-weight: 100;
    font-size: 25px;
}

.summaryItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.price{
    font-size: 25px;
}

.placeOrder{
    width: 100%;
    font-size: 20px;
    cursor: pointer;
    margin: 30px 0;
    font-family: poppins-light;
    color: white;
    border: none;
    outline: none;
    padding: 25px;
    border-radius: 8px;
    background-color: #AE796A;
    text-align: center;

}

.left-lower-part{
    width: 100%;
}

.left-lower-part p{
    font-size: 30px;
    font-family: poppins-light;
}

.method{
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 30px;
    font-size: 20px;
    border: 1px solid black;
    margin: 20px 0 ;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s;
}

.method label{
    cursor: pointer;
}
.method.disabled {
    position: relative;
    cursor: not-allowed;
}
.placeOrder:disabled {
    background-color: gray  ; /* Change the background color to a light gray */
    color: white; /* Change the text color to a darker gray */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
    /* Add any other styles you want for a disabled button */
  }
.coming-soon{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    pointer-events: none; /* Prevent interaction with the "Coming Soon" text */
    color: red;
    align-items: flex-end;
    font-weight: 700;
    font-size: 21px;
    height: 95%;
    width: 90%;
    margin-left: 8%;
}
.method:hover{
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.20);

}

.visa-imgs{
    display: flex;
    flex-direction: row;
    gap: 5px;
}



@media screen and (max-width: 1080px){

    .checkout-leftPart{
        align-items: center;
    }
    .checkoutHeader{
        flex-direction: column;
        align-items: center;
    }
    .checkoutHolder{

        flex-direction: column;
        align-items: center;
        font-size: 90%;

    }
    .checkout-leftPart{
        width: 95%; 
    }
    .address-form{

        justify-content: center;
        padding-left: 10px;
        padding-right: 20px;
    }
    .form-div{
        justify-content: space-between;
        gap: 30px;
        font-size: 15px;
    }
    .checkout-rightPart{
        width: 75%;
    }
    .placeOrder{
        padding: 15px;
    }
    .method{
        gap: 10px;
    }
}