.profileContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    height: 100%;
    border-radius: 38px;
    font-family: poppins;
    overflow-y: auto;
    cursor: pointer;
}

.mocketLogo img{
    width: 30%;
}

.mocketLogo{
    text-align: center;
    margin: 40px 0 10px 0;
}

.mocketCover {
    border-radius: 20px;
    text-align: center;
    width: 80%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mocketCover img{
    border-radius: 16px;
    width: 100%;
}

.mocketImage{
    margin-top: -55px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.70);
    width: 135px;
    height: 135px;
    border-radius: 50%;
    overflow: hidden;
}

.mocketImage img{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
}

.contactButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    margin: 10px 0;
    width: 90%;
}

.contactButtons a{
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 30px;
    padding: 7px;
    font-size: 13px;
    text-align: center;
    width: 100%;
    font-weight: 600;
}
.dashcontactButtons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    margin: 10px 0;
    width: 90%;
}

.dashcontactButtons a{
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 30px;
    padding: 7px;
    font-size: 9px;
    text-align: center;
    width: 100%;
    font-weight: 600;
}

.bio{
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bioTitle{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
    justify-content: center;
    padding: 10px;
    border-radius: 50%;
    font-size: 16px;
    width: 30px;
    z-index: 6;
    background: white;
}

.bio p{
    z-index: 1;
    margin-top: -20px;
    background-color: #D9D9D9;
    padding: 20px 10px 10px 10px;
    font-size: 15px;
    border-radius: 20px;
    text-align: center;
    white-space: pre-line;
    font-family: jakarta;
}

.nameHolder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nameHolder h1{
    margin: 10px 0 0 0 ;
    font-size: 18px;
    text-align: center;
}

.nameHolder h4{
    margin: 0 0 15px 0;
    font-size: 15px;
}

.removeContainer{
    height: 0;
    position: relative;
    right: -25px;
    top: -10px;
}

.removeIcon{
    transition: 0.3s;
}

.removeIcon:hover{
    color: red;
}

.businessLinkHolder{
    width: 90%;
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.businessLink{
    border-radius: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    overflow: hidden;
}

.businessRemoveContainer{
    margin-left: 15px;
}

.business-links-mobile{
    font-size: 13px;
}

.exchangeFormHolder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFF;
    margin: 0 auto;
    width: 80%;
    border-radius: 43px;
}


.exchangeForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.exchangeForm input, textarea{
    box-sizing: border-box;
    padding: 15px 10px;
    width: 80%;
    border: none;
    border-radius: 10px;
    background: #F2F2F2;
    outline: none;
}

.exchangeForm button{
    border: none;
    outline: none;
    border-radius: 8px;
    background: #AE796A;
    width: 90%;
    color: white;
    box-sizing: border-box;
    padding: 10px;
    margin-bottom: 40px;
}

.taskOpenBtnEx{
    color: black !important;
    text-decoration: none !important;
    color: white !important;
    background-color: black !important;
    border-radius: 30px !important;
    padding: 7px !important;
    font-size: 13px !important;
    text-align: center !important;
    line-height: unset !important;
}

.newAcc{
    margin: 20px auto;
    height: unset;
}

.newAccLoginForm{
    box-sizing: border-box;
    padding: 10px;  
    height: unset;
}

.exMUI{
    margin: 0 auto !important;
    position: relative !important;
    top: 16% !important;
}

@media screen and (max-width: 1080px){
    .mocketCover{
        overflow: hidden;
    }
}